import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { IIPO } from '7-entities/ipo';
import { useWindowSize } from '8-shared/hooks';
import shadow from '8-shared/assets/images/marletplace_deal_shadow.webp';

import { DealCard } from '../dealCard/dealCard';

interface IProps {
  companies: IIPO[];
}

export const MobileActualDeals = ({ companies }: IProps) => {
  const [slidePadding, setSlidePadding] = useState<string>('20px');
  const { width } = useWindowSize();

  useEffect(() => {
    if (width >= 640) {
      setSlidePadding('100px');
    } else if (width >= 0) {
      setSlidePadding('30px');
    }
  }, [width]);

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: true,
    centerPadding: slidePadding,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    arrows: false,
  };

  return (
    <div className={'laptop:hidden'}>
      <div className={'mt-[50px] z-[2] relative h-max desktop:px-[50px] large:px-[130px] mb-[90px] mobile:mb-[130px]'}>
        <img alt={'main_page_photo_3'} className={'absolute z-[3] w-[20%] h-full left-[-1px] top-0 opacity-60'} src={shadow} />
        <Slider {...settings}>
          {companies?.map((company, index) => (
            <DealCard company={company} isThird={index === 2} key={company?.id} />
          ))}
        </Slider>
        <img alt={'main_page_photo_3'} className={'absolute z-[3] w-[20%] h-full top-0 right-0 rotate-[180deg] opacity-60'} src={shadow} />
      </div>
    </div>
  );
};
