import React from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { selectP2pDeals, useGetP2PDealsQuery } from '7-entities/p2p';
import { useLocales } from '8-shared/lib/i18n';
import { ReactComponent as NextArrow } from '8-shared/assets/icons/arrow_right_1.svg';
import { ReactComponent as PrevArrow } from '8-shared/assets/icons/arrow_left_1.svg';

import { QuicklyDealsEmptyPage } from './quicklyDealsEmptyPage/quicklyDealsEmptyPage';
import { QuicklyDealCard } from './quicklyDealCard/quicklyDealCard';
import { BuyP2PScenario } from './buyP2PScenario/buyP2PScenario';

export const QuicklyDeals = () => {
  const { t } = useLocales('main');
  const p2pDeals = useSelector(selectP2pDeals);

  useGetP2PDealsQuery();

  const settings = {
    className: 'center',
    centerPadding: '115px',
    infinite: false,
    initialSlide: 0,
    dots: true,
    swipeToSlide: true,
    pauseOnHover: false,
    rows: 3,
    slidesToShow: 3,
    arrows: true,
    appendDots: (dots: never) => (
      <div style={{ background: 'transparent' }}>
        <ul style={{ background: 'transparent' }}> {dots} </ul>
      </div>
    ),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
        },
      },
    ],
  };

  if (!p2pDeals.length) {
    return <QuicklyDealsEmptyPage />;
  }

  return (
    <>
      <BuyP2PScenario />
      <div className={'px-0 large:px-[50px] mb-[50px]'}>
        <div className={'main_page_trading_bg py-[40px] px-0 rounded-30 mt-[90px] mobile:mt-[130px]'}>
          <div className={'px-[15px] font-500 text-3.6 leading-[44px] pb-[22px] mobile:pb-[30px] text-center'}>
            {t('quickly_deals.title')}
          </div>
          <div
            className={
              'px-[15px] font-400 text-1.4 laptop:text-2 leading-[20px] laptop:leading-[28px] pb-[22px] mobile:pb-[30px] text-center'
            }
          >
            {t('quickly_deals.sub_title')}
          </div>
          <div className={'px-[25px]'}>
            {!!p2pDeals?.length && (
              <Slider {...settings}>
                {p2pDeals?.map((p2p) => (
                  <QuicklyDealCard deal={p2p} key={p2p.id} />
                ))}
              </Slider>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
