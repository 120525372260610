import React from 'react';
import { Outlet } from 'react-router-dom';

// import { Helper } from '5-widgets/helper';
import { LayoutHeader, StickyLine } from '5-widgets/layoutHeader';
import { Footer } from '5-widgets/footer';
import { VerifyModal } from '5-widgets/verify/verifyModal';

export const AppLayout = () => {
  return (
    <div className={'text-white w-full h-full bg-main_bg flex flex-col justify-center'}>
      <StickyLine />
      <VerifyModal />
      <div className={'relative max-w-[1920px] m-auto w-full overflow-hidden min-h-screen h-full flex flex-col'}>
        <LayoutHeader />
        <Outlet />
        {/*         <div className={'flex flex-col flex-auto'}>
          <Helper />
        </div> */}
        <Footer />
      </div>
    </div>
  );
};
